<template>
    <div class="index">
        <!-- 头部导航组件 -->
        <navbar-header></navbar-header>
        <div style="min-width: 1200px;">
            <el-carousel :interval="5000" arrow="always" :height="imgHeight">
                <el-carousel-item v-for="(item,index) in imgList" :key="index">
                    <img style="width: 100%;" :src="item" alt="" id="inner" ref="imgHeight">
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 数字化物流运输平台 -->
        <div class="hswlfw animate__animated animate__slideInRight">
            <div class="bx box">
                <div class="title">数字化物流运输平台</div>
                <div class="annotation">公司深耕危化品产品，运用数字技术，提供专属运输信息化服务，促进上下游高效协同</div>
                <img src="../assets/img/zhanwei.png" style="width: 20px;height: 10px;margin: 10px 0 20px 0;" alt="">
                <div class="box-list">
                    <div class="min_tit">
                        <div class="border_bg">
                            <img src="../assets/img/zaitu.png" alt="">
                            <span>在途能源补给网络</span>
                        </div>
                        <div class="border_bg" style="margin: 20px 0;">
                            <img src="../assets/img/yunmao.png" alt="">
                            <span>运贸一体化平台</span>
                        </div>
                        <div class="border_bg">
                            <img src="../assets/img/keshihua.png" alt="">
                            <span>可视化在途跟踪</span>
                        </div>
                    </div>
                    <div class="centre_img">
                        <div>针对炼厂、油库、加油站、化工厂、矿区等</div>
                        <div class="chunk"></div>
                        <div class="centre_nr">主要运输:燃料油、石脑油、凝析油、常柴、催柴、轻循环油、工业三苯、汽柴油、甲醇等大三类油品</div>
                    </div>
                    <div class="min_tit">
                        <div class="border_bg">
                            <img src="../assets/img/yunshu.png" alt="">
                            <span>运输供应链金融</span>
                        </div>
                        <div class="border_bg" style="margin: 20px 0;">
                            <img src="../assets/img/hegui.png" alt="">
                            <span>合规发票抵扣链条</span>
                        </div>
                        <div class="border_bg">
                            <img src="../assets/img/ETC.png" alt="">
                            <span>ETC发票获取</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 核心优势 -->
        <div class="hxys">
            <div class="bx box">
                <div class="title">核心优势</div>
                <img src="../assets/img/zhanwei.png" style="width: 20px;height: 10px;margin: 10px 0 20px 0;" alt="">
                <div class="hxys_content">
                    <div :class="index == 1 ? 'hxys_left1 hxys_left animate__animated animate__fadeIn' : 'hxys_left animate__bounceOut'" v-show="index == 1">
                        <div class="hxys_left_con">
                            提供Alii-in-one的整套数字化系统，打通延长化工电子商务系统，涵盖物流管理，网络货运，电子油卡，资金结算，供应链金融服务等物流链路信息化系统，实现从订单到物流一体化解决方案。
                        </div>
                    </div>
                    <div :class="index == 2 ? 'hxys_left2 hxys_left animate__animated animate__fadeIn' : 'hxys_left animate__bounceOut'" v-show="index == 2">
                        <div class="hxys_left_con">
                            覆盖全国高速公路、国道省道等大型货运网络沿线的加油、加氢、加气、充电站，数万座站点。
                            直联能源补给底层系统，提供更优惠，便捷的能源补给策略。 网点分布更加精准匹配降低运输用油成本，价格更优惠，资金清结算自动化，高效便利。
                        </div>
                    </div>
                    <div :class="index == 3 ? 'hxys_left3 hxys_left animate__animated animate__fadeIn' : 'hxys_left animate__bounceOut'" v-show="index == 3">
                        <div class="hxys_left_con">
                            网络货运业务赋能：助力物流企业更加轻松办理网络货运业务各个阶段 ——设立阶段、运营阶段等。<br/>
                            降本赋能：网络平台油品抵扣更加合规和轻松实现。 数据赋能：通过数据链和数据模型提供供应链金融服务支撑，保险和车后等。
                        </div>
                    </div>
                    <div class="hxys_right">
                        <div :class="index == 1 ? 'pitchoOn' : ''" @mouseenter="index = 1">
                            <span>一体化的系统优势</span>
                            <p></p>
                            <span class="hxys_right_con">提供Alii-in-one的整套数字化系</span>
                        </div>
                        <div :class="index == 2 ? 'pitchoOn' : ''" @mouseenter="index = 2">
                            <span>全国性的能源补给网点优势</span>
                            <p></p>
                            <span class="hxys_right_con">覆盖全国高速公路、国道省道等大型货运网络</span>
                        </div>
                        <div :class="index == 3 ? 'pitchoOn' : ''" @mouseenter="index = 3">
                            <span>精准赋能</span>
                            <p></p>
                            <span class="hxys_right_con">通过数据链和数据模型提供供应链金融服务支</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 延长数字化物流生态 -->
        <div class="ycst">
            <div class="bx box">
                <div class="title">延长数字化物流生态</div>
                <img src="../assets/img/zhanwei.png" style="width: 20px;height: 10px;margin: 10px 0 20px 0;" alt="">
                <img src="../assets/img/zoology.png" style="width: 1400px;" alt="">
            </div>
        </div>

        <!-- 延长数字化物流生态 -->
        <div class="xwzx">
            <div class="bx box">
                <div class="title">新闻中心</div>
                <img src="../assets/img/zhanwei.png" style="width: 20px;height: 10px;margin: 10px 0 20px 0;" alt="">
                <div class="xwzx_list">
                    <div class="xwzx_ts">
                    <!-- background: url(../assets/img/xinwen.png) no-repeat; -->
                        <div class="xinwen_bg" :style="list[xinwenIndex-1] ? 
                        'background: url(' + list[xinwenIndex-1].coverImg + ') no-repeat;' : ''" @click="skip()">
                            <div>
                                <i class="el-icon-arrow-left arrow" @click.stop="flipOver(true)"></i>
                                <i class="el-icon-arrow-right arrow" style="margin-left: 4px;" @click.stop="flipOver(false)"></i>
                            </div>
                        </div>
                        <div style="display: flex;justify-content: space-between;">
                            <div class="rq">
                                <span>{{list[xinwenIndex-1] ? new Date(list[xinwenIndex-1].createTime).format('dd') : '---'}}</span>
                                <span style="font-size: 12px;">{{list[xinwenIndex-1] ? new Date(list[xinwenIndex-1].createTime).format('yyyy-MM') : '---'}}</span>
                            </div>
                            <div class="bt">
                                {{ list[xinwenIndex-1] ? list[xinwenIndex-1].title : '---' }}
                            </div>
                        </div>
                    </div>
                    <div class="xwzx_item" @click="skip(item,index)" :style="xinwenIndex-1 == index ? 'background: #c50007;color: #fff;' : ''"
                        v-for="(item,index) in list" :key="item.id">
                        <div class="rq">{{new Date(item.createTime).format('MM.dd')}}</div>
                        <div class="year">{{new Date(item.createTime).format('yyyy')}}</div>
                        <div class="bt">{{ item.title }}</div>
                        <div class="zy">
                            {{ item.synopsis }}
                        </div>
                    </div>
                </div>
                <div style="color:#c50007;margin-top: 20px;cursor: pointer;" @click="$router.push('/NewsBulletin')">查看更多<i class="el-icon-d-arrow-right"></i></div>
            </div>
        </div>

        <!-- 底部 -->
        <nav-footer></nav-footer>

    </div>
</template>

<script>
import NavbarHeader from "../components/home/newHeader.vue"
import NavFooter from "../components/common/NavFooter.vue"
import Cookies from 'js-cookie'

export default {
    components: {
        NavbarHeader,
        // NewsList,
        NavFooter
    },

    data () {
        return {
            imgHeight: '0px',
            screenheight: 0,
            // 刷新
            isRefresh: false,
            newsList: [],
            newsCatgoryList: [],
            imgList: [],
            isAbsolute: true,
            swiperList: [], // 轮播图列表
            index: 1,
            xinwenIndex: 1,
            list: [],
            total: 0
        }
    },
    computed: {
    },
    mounted () {
        this.imgHeight = '640px'
        let that = this
        window.onresize = function temp () {
            // 通过点语法获取img的height属性值
            that.imgHeight = `${that.$refs.imgHeight['0'].height}px`
        }
        // 查询轮播图
        this.getSwiperList();
        this.getNewList()
    },
    methods: {
        skip (item, index) {
            let query = {
                id: item ? item.id : this.list[this.xinwenIndex - 1].id,
                page: 1,
                limit: 3,
                index: item ? index : 0,
                categoryId: '',
                total: this.total
            }

            this.$router.push({ name: 'tradingInformationParticulars', query: query });
        },
        getNewList () {
            this.$axios.get("/yc/new/list", {
                params: {
                    page: 1,
                    limit: 3
                }
            }).then(res => {
                if (res.data.code == '200') {
                    this.list = res.data.data.list
                    this.total = res.data.data.total
                    // this.xinwenIndex = this.list[0].id
                }
            })
        },
        //翻页
        flipOver (val) {
            if (val) {
                this.xinwenIndex--
            } else {
                this.xinwenIndex++
            }
            if (this.xinwenIndex > 3) {
                this.xinwenIndex = 3
            }
            if (this.xinwenIndex < 1) {
                this.xinwenIndex = 1
            }
        },
        /**
         * 获取轮播与头条
         */
        getSwiperList () {
            this.$axios.get("/yc/rotationImg/list", {
                params: {
                    type: 1
                }
            }).then(res => {
                if (res.data.code == '200') {
                    this.imgList = []
                    res.data.data.list.forEach(e => {
                        this.imgList.push(e.rotationImg)
                    })
                    setTimeout(() => {
                        this.imgHeight = `${this.$refs.imgHeight['0'].height}px`
                    }, 500);
                }
            })
        },

        /**
         * 跳转详情
         */
        toDetail (id) {
            // 更新点击数
            // this.clickNum(id);
            let routeUrl = this.$router.resolve({
                path: `/detail/${id}`,
                query: {
                    page: 1,
                    pageSize: 3
                }
            });
            window.open(routeUrl.href, '_blank');
        },

        /**
         * 跳转查看更多新闻
         */
        toNewsList () {
            var newsCatgoryList = this.newsCatgoryList;
            if (newsCatgoryList.length == 0) {
                this.$message({
                    type: 'error',
                    message: '请先配置新闻页'
                })
                return;
            }
            var categoryObj = newsCatgoryList[0]; // 新闻公告
            this.$router.push({
                path: categoryObj.path + `/${categoryObj.id}`,
            })
        },

        /**
        * 查询顶部栏目
        */
        queryList () {
            var params = {
                page: 1,
                limit: 10,
            }
            this.$axios.post('/qx/column/listTree', params).then(res => {
                // console.log(`res = ${JSON.stringify(res)}`);
                if (res.data.code != '200') {
                    return;
                }
                var resultList = res.data.data;
                resultList = resultList.filter(f => f.path.indexOf('commNewsList') > 0);
                this.newsCatgoryList = resultList;
            });
        },
        /**
         * 展示右侧新闻
         */
        queryNewList (id) {
            // 栏目id
            var params = {
                page: 1,
                limit: 3,
                title: '',
            }
            this.isLoading = true;
            this.$axios.post("/qx/new/gxNewsList", params).then(res => {
                // console.log(`res = ${JSON.stringify(res)}`);
                this.isLoading = false;
                if (res.data.code == '200') {
                    var list = res.data.data.list;
                    list.forEach(item => {
                        item.createTime = new Date(item.createTime).format('yyyy-MM-dd');
                    })
                    this.newsList = list;
                }
            });
        },

        /**
         * 定时器获取token
         */
        checkTokenTimeOut () {
            var that = this;
            var res = setInterval(function () {
                var obj = Cookies.get("wangZhanLoginUser");
                if (obj) {
                    var loginUser = JSON.parse(obj);
                    that.loginUser = loginUser;

                    window.localStorage.setItem('token', loginUser.token);
                    window.localStorage.setItem('userInfo', loginUser.userInfo);
                }
            }, 1000)
            window.localStorage.setItem('tokenTimeout', res)
        },
    },
}
</script>

<style lang="scss" scoped>
body {
    font-family: "Arial Normal", "Arial";
}
.hswlfw {
    min-width: 1200px;
    box-sizing: border-box;
    height: 500px;
    display: flex;
    align-items: center;
    background: #f3f7fc;
    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            font-size: 26px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 10px;
        }
        .annotation {
            font-size: 12px;
            color: #000;
        }
        .box-list {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 14px;

            .min_tit {
                flex: 3;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .border_bg {
                    background: #fff9f9;
                    border: 1px solid #f5f0f0;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: #272322;
                    font-weight: 600;
                    img {
                        margin: 14px 20px;
                    }
                }
            }
            .centre_img {
                flex: 5;
                margin: 0 20px;
                background: url(../assets/img/platform_bg.png) no-repeat;
                background-size: 100% 100%;
                color: #fff;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 10px;
                .chunk {
                    width: 20px;
                    height: 2px;
                    background: #fff;
                    margin: 10px 0;
                }
                .centre_nr {
                    font-size: 13px;
                }
            }
        }
    }
}
.hxys {
    min-width: 1200px;
    box-sizing: border-box;
    height: 600px;
    display: flex;
    align-items: center;
    background: url(../assets/img/bg_3.png) no-repeat;
    background-size: 100% 100%;
    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            font-size: 26px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 10px;
        }
        .hxys_content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            .hxys_left {
                display: flex;
                flex-direction: column-reverse;
                .hxys_left_con {
                    background: #00000048;
                    font-size: 12px;
                    color: #fff;
                    padding: 16px 20px;
                }
            }
            .hxys_left3 {
                width: 600px;
                height: 330px;
                background: url(../assets/img/hx_bg1.png) no-repeat;
                background-size: 100% 100%;
            }
            .hxys_left2 {
                width: 600px;
                height: 330px;
                background: url(../assets/img/hx_bg2.png) no-repeat;
                background-size: 100% 100%;
            }
            .hxys_left1 {
                width: 600px;
                height: 330px;
                background: url(../assets/img/hx_bg3.png) no-repeat;
                background-size: 100% 100%;
            }
            .hxys_right {
                width: 600px;
                height: 330px;
                font-size: 18px;
                div {
                    width: 600px;
                    height: 110px;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    border: 1px solid rgba(34, 31, 31, 0.05);
                    cursor: pointer;
                    p {
                        width: 20px;
                        height: 2px;
                        background: #000;
                        margin: 10px 0;
                    }
                    .hxys_right_con {
                        font-size: 14px;
                    }
                }
                .pitchoOn {
                    width: 612px;
                    height: 110px;
                    position: relative;
                    left: -12px;
                    background: url(../assets/img/bg_2.png) no-repeat;
                    background-size: 100% 100%;
                    border: none;
                    padding-left: 32px;
                    color: #f7dbdb;
                    p {
                        background: #fff;
                    }
                }
            }
        }
    }
}
.ycst {
    min-width: 1400px;
    box-sizing: border-box;
    height: 800px;
    display: flex;
    align-items: center;
    background: url(../assets/img/dang.png) no-repeat;
    background-size: 100% 100%;
    .box {
        width: 1400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            font-size: 26px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 10px;
        }
    }
}
.xwzx {
    min-width: 1200px;
    box-sizing: border-box;
    height: 700px;
    display: flex;
    align-items: center;
    background: url(../assets/img/bg_1.png) no-repeat;
    background-size: 100% 100%;
    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            font-size: 26px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 10px;
        }
        .xwzx_list {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .xwzx_ts {
                flex: 4;
                height: 300px;
                background: #ffffff;
                box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
                border-radius: 2px;
                overflow: hidden;
                .xinwen_bg {
                    width: 100%;
                    height: 240px;
                    background: url(../assets/img/xinwen.png) no-repeat;
                    background-size: 100% 100%;
                    display: flex;
                    padding: 4px;
                    justify-content: flex-end;
                    align-items: flex-end;
                }
                .rq {
                    width: 60px;
                    height: 60px;
                    background: #c50007;
                    color: #ffffff;
                    font-family: "Lucida Sans", "Lucida Sans Regular",
                        "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana,
                        sans-serif;
                    font-size: 26px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-left: 1px;
                }
                .bt {
                    width: calc(100% - 60px);
                    padding: 10px;
                    height: 52px;
                    font-size: 14px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                }
            }
            .xwzx_item {
                cursor: pointer;
                flex: 3;
                margin-left: 20px;
                height: 300px;
                background: #ffffff;
                box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
                border-radius: 2px;
                padding: 20px;
                color: #221f1f;
                .rq {
                    font-size: 36px;
                    font-family: "Lucida Sans", "Lucida Sans Regular",
                        "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana,
                        sans-serif;
                    font-weight: normal;
                    line-height: 44px;
                }
                .year {
                    margin: 6px 0;
                    font-size: 12px;
                    font-weight: normal;
                    line-height: 14px;
                }
                .bt {
                    margin-bottom: 10px;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 22px;
                }
                .zy {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 24px;
                    height: 144px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 6;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}

.arrow {
    width: 30px;
    height: 30px;
    background: #c50007;
    color: #fff;
    cursor: pointer;
    border-radius: 2px;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
}
/deep/ .el-carousel__item {
    min-width: 1200px !important;
}
</style>